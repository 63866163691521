// extracted by mini-css-extract-plugin
export var active = "internal--flows-nj7c62heryy6najv-module--active--89fcd";
export var checkoutUrl = "internal--flows-nj7c62heryy6najv-module--checkoutUrl--39937";
export var container = "internal--flows-nj7c62heryy6najv-module--container--05448";
export var groupContainer = "internal--flows-nj7c62heryy6najv-module--groupContainer--ff814";
export var groupTitle = "internal--flows-nj7c62heryy6najv-module--groupTitle--e9409";
export var pageInfoContainer = "internal--flows-nj7c62heryy6najv-module--pageInfoContainer--7533a";
export var pageTitle = "internal--flows-nj7c62heryy6najv-module--pageTitle--e36f2";
export var secondIndent = "internal--flows-nj7c62heryy6najv-module--secondIndent--f65f9";
export var textStyleLargeTitle = "internal--flows-nj7c62heryy6najv-module--text-style-large-title--ab8ff";
export var textStyleLargestTitle = "internal--flows-nj7c62heryy6najv-module--text-style-largest-title--723ed";
export var textStyleMediumTitle = "internal--flows-nj7c62heryy6najv-module--text-style-medium-title--1b11f";
export var textStyleSmallTitle = "internal--flows-nj7c62heryy6najv-module--text-style-small-title--7aa94";