import React, { useState } from 'react';
import { graphql } from 'gatsby';

import * as styles from './internal--flows-nj7c62heryy6najv.module.scss';
import { Module } from '../../components/ui/Section';
import { CHECKOUT_URL, FILE_DOWNLOAD_URLS_BY_PARAM_VALUE } from '../../constants';
import { OverridablePlan } from '../../components/modules/PlansModule';
import PageSEO from '../../components/PageSEO';
import { clsx, urlJoin } from '../../utils/utils';
import { uniq } from '../../utils/nodash';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Helmet } from 'react-helmet';
import REDIRECTS_FILE_CONTENT from '../../../_redirects';
import { replaceNewLinesWithBr } from '../../utils/sanity';

export const query = graphql`
  query {
    allSanityGtgvLandingPage {
      totalCount
      nodes {
        slug {
          current
        }
        destinationUrlOnSubmit
        onSubmitKeapTagIds
      }
    }
    allSanityLpViralPage {
      totalCount
      nodes {
        isHomepage
        title
        slug {
          current
        }
        hero {
          plansPage {
            slug {
              current
            }
          }
          onSubmitKeapTagIds
        }
        sections {
          content {
            ...Modules
          }
        }
      }
    }
    allSanityLpViralPlansPage {
      totalCount
      nodes {
        title
        slug {
          current
        }
        sections {
          content {
            ...Modules
          }
        }
      }
    }
    allSanityLpVslPage {
      nodes {
        internalName
        slug {
          current
        }
        sections {
          content {
            ...Modules
          }
        }
      }
    }
  }
`;

export interface QueryData {
  data: {
    allSanityGtgvLandingPage: {
      nodes: Array<{
        slug: {
          current: string;
        };
        destinationUrlOnSubmit: string;
        onSubmitKeapTagIds: string;
      }>;
    };
    allSanityLpViralPage: {
      nodes: Array<{
        title: string;
        isHomepage: boolean;
        slug: {
          current: string;
        };
        hero: {
          plansPage: {
            slug: {
              current: string;
            };
          };
          onSubmitKeapTagIds: string;
        };
        sections: Array<{
          content: Array<Module>;
        }>;
      }>;
    };
    allSanityLpViralPlansPage: {
      nodes: Array<{
        title: string;
        slug: {
          current: string;
        };
        sections: Array<{
          content: Array<Module>;
        }>;
      }>;
    };
    allSanityLpVslPage: {
      nodes: Array<{
        internalName: string;
        slug: {
          current: string;
        };
        sections: Array<{
          content: Array<Module>;
        }>;
      }>;
    };
  };
}

const FlowPage = ({ data }: QueryData): React.ReactElement => {
  const [active, setActive] = useState<string | null>(null);

  const { nodes: gtgvPages } = data.allSanityGtgvLandingPage;
  const { nodes: viralPages } = data.allSanityLpViralPage;
  const { nodes: plansPages } = data.allSanityLpViralPlansPage;
  const { nodes: vslPages } = data.allSanityLpVslPage;

  const viralHomepages = viralPages.filter(page => page.isHomepage);

  function getCheckoutUrl(overridablePlan: OverridablePlan): string {
    return overridablePlan.plan
      ? CHECKOUT_URL +
          '/?add-to-cart=' +
          overridablePlan.plan.planId +
          (overridablePlan.coupon ? '&coupon_code=' + overridablePlan.coupon : '')
      : '';
  }

  function getPlanInfo(overridablePlan: OverridablePlan): React.ReactElement {
    return (
      <>
        <li className={styles.planName}>
          <strong>Plan name:</strong>
          {overridablePlan.name || overridablePlan.plan.name}
        </li>
        <ul className={styles.secondIndent}>
          <li>
            Keap Tag Id:
            {overridablePlan.keapTagId || overridablePlan.plan.keapTagId}
          </li>
          <li>Price: {overridablePlan.plan ? overridablePlan.plan.price : ''}</li>
          <li>Price with discount: {overridablePlan.priceWithDiscount || ''}</li>
          <li>Coupon: {overridablePlan.coupon || ''}</li>
          <li>
            Plan type:
            {overridablePlan.plan ? overridablePlan.plan.planType : ''}
          </li>
          <li>
            Woocommerce Id:
            {overridablePlan.plan ? overridablePlan.plan.planId : ''}
          </li>
          {overridablePlan.plan && overridablePlan.plan.isCustomQuotePlan && (
            <li>Is Custom Quote Plan: Yes</li>
          )}
          {overridablePlan.plan && !overridablePlan.plan.isCustomQuotePlan && (
            <li>
              Checkout url:
              <a
                href={getCheckoutUrl(overridablePlan)}
                onClick={() => setActive(getCheckoutUrl(overridablePlan))}
              >
                {' ' + getCheckoutUrl(overridablePlan)}
              </a>
            </li>
          )}
        </ul>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <PageSEO defaultTitle="Viral Flows" />
      <div className={styles.container}>
        <div className={styles.groupContainer}>
          <span className={styles.groupTitle}>Guide To Going Viral Pages</span>
          {gtgvPages.map((gtgvPage, i) => (
            <div className={styles.pageInfoContainer} key={i}>
              <p>
                <strong>Slug:</strong> {gtgvPage.slug.current}
              </p>
              <p>
                <strong>Destination url:</strong>
                <AnchorLink
                  href={'#' + urlJoin(gtgvPage.destinationUrlOnSubmit.split(/(^.*)?\?/)[1])}
                  onClick={() =>
                    setActive(urlJoin(gtgvPage.destinationUrlOnSubmit.split(/(^.*)?\?/)[1]))
                  }
                >
                  {urlJoin(gtgvPage.destinationUrlOnSubmit)}
                </AnchorLink>
              </p>
              <p>
                <strong>Keap Tag Id:</strong>
                {gtgvPage.onSubmitKeapTagIds}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.groupContainer}>
          <span className={styles.groupTitle}>Viral Homepages</span>
          {viralHomepages.map((viralHomepage, i) => (
            <div
              className={clsx(
                styles.pageInfoContainer,
                active === urlJoin(viralHomepage.slug.current) && styles.active,
              )}
              key={i}
              id={urlJoin(viralHomepage.slug.current)}
            >
              <p className={styles.pageTitle}>
                <strong>{viralHomepage.title}</strong>
              </p>
              <p>
                <strong>Slug:</strong>
                {urlJoin(viralHomepage.slug.current)}
                <p>
                  <strong>Hero:</strong>
                </p>
                <ul>
                  <li>
                    Plans page slug:
                    <AnchorLink
                      href={'#' + urlJoin(viralHomepage.hero.plansPage.slug.current)}
                      onClick={() => setActive(urlJoin(viralHomepage.hero.plansPage.slug.current))}
                    >
                      {urlJoin(viralHomepage.hero.plansPage.slug.current)}
                    </AnchorLink>
                  </li>
                  <li>Keap Tag Id: {viralHomepage.hero.onSubmitKeapTagIds}</li>
                </ul>
              </p>
              {viralHomepage.sections.map(section =>
                section.content
                  .filter(module => !module.disabled)
                  .map(module => {
                    if (module.__typename === 'SanityLpViralCtaModule') {
                      return (
                        <div>
                          <p>
                            <strong>CTA Module:</strong>
                          </p>
                          <ul>
                            <li>
                              Plans page slug:
                              <AnchorLink
                                href={'#' + urlJoin(module.plansPage.slug.current)}
                                onClick={() => setActive(urlJoin(module.plansPage.slug.current))}
                              >
                                {urlJoin(module.plansPage.slug.current)}
                              </AnchorLink>
                            </li>
                            <li>Keap Tag Id: {module.onSubmitKeapTagIds}</li>
                          </ul>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  }),
              )}
            </div>
          ))}
        </div>
        <div className={styles.groupContainer}>
          <span className={styles.groupTitle}>Plans Pages</span>
          {plansPages.map((planPage, i) => (
            <div
              className={clsx(
                styles.pageInfoContainer,
                active === urlJoin(planPage.slug.current) && styles.active,
              )}
              key={i}
              id={urlJoin(planPage.slug.current)}
            >
              <p className={styles.pageTitle}>
                <strong>{planPage.title}</strong>{' '}
              </p>
              <p>
                <strong>Slug:</strong> {planPage.slug.current}
              </p>
              {planPage.sections.map(section =>
                section.content
                  .filter(module => !module.disabled)
                  .map(module => {
                    if (module.__typename === 'SanityLpViralPlansModule') {
                      return (
                        <div>
                          <p>
                            <strong>Plans Module:</strong>
                          </p>
                          {module.plans.map((overridablePlan, i) => (
                            <ul key={i}>{getPlanInfo(overridablePlan)}</ul>
                          ))}
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  }),
              )}
            </div>
          ))}
        </div>
        <div className={styles.groupContainer}>
          <span className={styles.groupTitle}>VSL Pages</span>
          {vslPages.map((vslPage, i) => (
            <div className={styles.pageInfoContainer} key={i}>
              <p className={styles.pageTitle}>
                <strong>{vslPage.internalName}</strong>
              </p>
              <p>
                <strong>Slug:</strong> {vslPage.slug.current}
              </p>
              {vslPage.sections.map(section =>
                section.content
                  .filter(module => !module.disabled)
                  .map(module => {
                    if (module.__typename === 'SanityLpViralPlansModule') {
                      return (
                        <div>
                          <p>
                            <strong>Plans Module:</strong>
                          </p>
                          {uniq(module.plans).map((overridablePlan, i) => (
                            <ul key={i}>{getPlanInfo(overridablePlan)}</ul>
                          ))}
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  }),
              )}
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: 20, padding: 20 }}>
        <h2 style={{ marginBottom: 20 }}>Redirects file</h2>
        {replaceNewLinesWithBr(REDIRECTS_FILE_CONTENT)}
      </div>
      <div style={{ marginTop: 20, padding: 20 }}>
        <h2 style={{ marginBottom: 20 }}>File urls per file url parameter</h2>
        {Object.entries(FILE_DOWNLOAD_URLS_BY_PARAM_VALUE).map(([urlParam, fileUrl], i) => (
          <p key={i}>
            <strong>{urlParam}:</strong> <a href={fileUrl}>{fileUrl}</a>
          </p>
        ))}
      </div>
    </>
  );
};

export default FlowPage;
